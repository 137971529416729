import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import requests from "../../../../../services/requests";
import Table from "../../../../../components/styles/Table";
import Container from "./styles";
import moment from "moment";
import Paginate from "../../../../../components/Paginate/Paginate";

const LogTable = ({ filterInformation, searchString, searchPeriod }) => {
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });

    const getData = () => {
        requests
            .requestLogs(
                {
                    search: searchString,
                    filters: filterInformation,
                    period: searchPeriod,
                },
                meta.current_page > 0 ? meta.current_page : 1
            )
            .then(res => {
                setData(res.data);
                setMeta(res.meta);
                setLoad(false);
            });
    };

    useEffect(() => {
        getData();
    }, [searchString, filterInformation, searchPeriod, action]);

    return (
        <>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <Container>
                    <Table>
                        <thead>
                            <tr>
                                <th>Horário</th>
                                <th>status</th>
                                <th>Método</th>
                                <th>Rota</th>
                                <th>Usuário</th>
                                <th>Perfil</th>
                                <th>IP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(row => (
                                <tr key={row?.identifier}>
                                    <td>{moment(row?.createdAt).format("HH:mm - DD MMM YYYY")}</td>
                                    <td>{row?.requestStatus}</td>
                                    <td>{row?.requestMethod}</td>
                                    <td>{row?.requestUrl}</td>
                                    <td>{row?.user?.name ? row?.user?.name : "Não definido"}</td>
                                    <td>{row?.roleHumanReadable}</td>
                                    <td>{row?.ip}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            )}
            {meta.total_pages > 1 && <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} />}
        </>
    );
};

export default LogTable;
