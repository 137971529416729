import styled from "styled-components";

import { colors } from "../../../../../assets/colors";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    min-width: 90vw;
    overflow-x: auto;
    div {
        background-color: ${colors.C1};
        padding: 5px 18px;
        margin-top: 10px;
        align-self: flex-end;
    }
    strong {
        color: ${colors.roxo_oficial};
        padding-right: 20px;
        font-size: 16px;
    }

    span {
        color: ${colors.roxo_oficial};
        font-size: 16px;
    }
`;

export default Section;
